import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-header-sub-title',
  templateUrl: './header-sub-title.component.html',
  styleUrls: ['./header-sub-title.component.scss'],
})
export class HeaderSubTitleComponent implements OnInit {
  @Input() text: string
  constructor() { }

  ngOnInit() {}

}
