import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../../services/utils.service";
import {environment} from "../../../../environments/environment";
import {CrudService} from "../../../services/crud.service";

@Component({
  selector: 'filter-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss'],
})
export class FilterRouteComponent implements OnInit {
  theme: any;
  private the_id: string;
  constructor(private route: ActivatedRoute, private crudService: CrudService) { }

  ngOnInit() {
    this.the_id = this.route.snapshot.paramMap.get('the_id');

    this.crudService.singleData("ta_theme", "the_id", Number(this.the_id), environment.apiKey).then((res: any[]) => {
      if(res){
        this.theme = res[0]
        this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['the_id', res[0].the_id], environment.apiKey).then(res2=>{
          this.theme.content = []
          this.theme.content.push(res2)
        })
      }
    })
  }

  getThemeColor() {
    if(this.theme.length > 0){
      return this.theme.the_tag + '-bg-color'
    }
  }
}
