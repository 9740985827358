import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from "../../../environments/environment";
import {NavigationService} from "../../services/navigation.service";
import {FiltersComponent} from "../filters/filters.component";
import {UtilsService} from "../../services/utils.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent implements OnInit {
  @Input() theme: string ;
  @Input() page: string ;
  @Output() typeFilterMenu = new EventEmitter<any>();
  @ViewChild(FiltersComponent) filterComponent: FiltersComponent
  filter: string = '';
  media = environment.data.media
  openMenu: boolean = false;
  constructor(private route: ActivatedRoute, private navigationService: NavigationService, public utilsService: UtilsService) { }

  ngOnInit() {
    this.filter = this.route.snapshot.paramMap.get('filter')
    this.typeFilterMenu.emit('information')
  }

  routerLink(){
    this.navigationService.goBack()
  }

  openMenuByClick() {
    this.openMenu = !this.openMenu
    this.utilsService.openMenu = this.openMenu
  }

  changeComponent($event: any) {
    this.openMenu = !this.openMenu;
    this.typeFilterMenu.emit($event)
  }
}
