import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'filter-speak',
  templateUrl: './speak.component.html',
  styleUrls: ['./speak.component.scss'],
})
export class FilterSpeakComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
