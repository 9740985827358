import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonSlides} from "@ionic/angular";
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../services/utils.service";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  @ViewChild('ionSlides') slides: IonSlides
  @Input() media: any
  @Input() item: string;
  @Input() filter: string;
  theme: string;
  slideOpts = {
    initialSlide: 0,
    speed: 400,
    autoHeight: true,
  };
  imgMuted: string = "assets/icon/filters/tholen_app_filter_muted.png";
  muted = true;
  private page: string;
  showSlider: boolean = true;

  constructor(private route: ActivatedRoute, public utilsService: UtilsService) { }

  ngOnInit() {
    let the_id = this.route.snapshot.paramMap.get('the_id')
    this.page = this.route.snapshot.paramMap.get('page')
    if(this.page == 'route'){

      this.filter = this.route.snapshot.paramMap.get('filter')

      if(this.filter == 'poi'){
        let arr = []
        for(let item of this.media){
          if(item.med_extra_info != 'header'){
            arr.push(item)
          }
        }
        this.media = arr
        this.showSlider = this.media.length > 1
      }
    }
    if(!the_id){
      the_id = '6'
    }
    this.theme = this.utilsService.getThemeTag(the_id)
  }
}
