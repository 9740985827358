import {Component, OnInit, ViewChild} from '@angular/core';
import {IonSlides} from "@ionic/angular";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {CrudService} from "../../../../services/crud.service";
import {UtilsService} from "../../../../services/utils.service";

@Component({
  selector: 'app-poi',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss'],
})
export class PoiComponent implements OnInit {
  @ViewChild('ionSlidesPoi') slidesPoi: IonSlides
  headerSubTitle: string;
  item: any;
  lastOrder;
  slideOptsPoi = {
    initialSlide: 0,
    speed: 400,
    autoHeight: true,
    allowTouchMove: false
  };
  theme: any;
  private total: number;
  private timeline: HTMLElement;
  media = environment.data.media;

  constructor(private route: ActivatedRoute, private crudService: CrudService, private utilsService: UtilsService) { }

  ngOnInit() {
    this.item = JSON.parse(localStorage.getItem('route'))
    if(localStorage.getItem('routeProgress')){
      this.lastOrder = Number(JSON.parse(localStorage.getItem('routeProgress')).progress) - 1
      this.slideOptsPoi.initialSlide = this.lastOrder
    }
    else{
      this.lastOrder = 0
    }
    let the_id = this.route.snapshot.paramMap.get('the_id')
    this.theme = this.utilsService.getThemeTag(the_id)
  }

  convertJSONtoString(it: any, prop: string) {
      if(it.content[0]){
        return JSON.parse(it.content[0][0].con_text)[prop]
      }
      else{
        return 'Informatie tijdelijk niet beschikbaar.'
      }
  }

  navigator(id){
    let vw50 = window.innerWidth / 2;
    let index;
    let bullets = document.getElementsByClassName('poi-bullet') as HTMLCollectionOf<HTMLElement>;
    let timelineContainer = document.getElementsByClassName('timeline-container') as HTMLCollectionOf<HTMLElement>
    let els = document.getElementsByClassName('animation') as HTMLCollectionOf<HTMLElement>
    let direction;
    if(id > this.slideOptsPoi.initialSlide) {
      direction = 'next'

      index = id - 1;
    }
    else {
      direction = 'prev'
      index = id + 1;

    }
    for(let i = 0; i < els.length; i++){
      els[i].style.animationName = 'poiTitleDeactive'
      els[i].style.animationDuration = '200ms'
      setTimeout(()=>{
        els[i].classList.remove('active')
      }, 180)
    }
    bullets[index].style.animationName = `bulletToDeactive${this.theme}`;
    bullets[index].style.animationDuration = '380ms';
    setTimeout(()=>{
      bullets[index].style.left = `${Number(bullets[index].style.left.split('px')[0]) + 9}px`
    },150)
    setTimeout(()=>{
        bullets[index].style.width = '30px'
        bullets[index].style.height = '30px'
        bullets[index].style.backgroundColor = 'white'
        bullets[index].style.top = '8px'
        bullets[index].classList.remove('active')
        let left = bullets[index].style.left.split('px')[0]
        if(direction == 'next'){
          this.slideOptsPoi.initialSlide++
          this.slidesPoi.slideTo(this.slideOptsPoi.initialSlide)
          index++
        }
        else{
          this.slideOptsPoi.initialSlide--
          this.slidesPoi.slideTo(this.slideOptsPoi.initialSlide)
          index--
        }
        if(index == 0 ){
          timelineContainer[0].style.left = vw50 - 15 + 'px'
        }
        else if(index == 1) {
          timelineContainer[0].style.left = -15 + 'px'
        }
        else{
          let vw50 = -window.innerWidth / 2
          timelineContainer[0].style.left = `${(vw50 * (index - 1)) - 15}px`
        }
        setTimeout(()=>{
          bullets[index].classList.add('active')
          bullets[index].style.animationName = `bulletToActive${this.theme}`;
          bullets[index].style.animationDuration = '380ms';
          setTimeout(()=>{
            bullets[index].style.left = `${Number(bullets[index].style.left.split('px')[0]) - 9}px`
            bullets[index].style.top = "0"
            bullets[index].style.backgroundColor = environment.themeColorValues[this.theme]
            bullets[index].style.width = '50px'
            bullets[index].style.height = '50px'
            setTimeout(()=>{
              for(let i = 0; i < els.length; i++){
                els[i].style.animationName = 'poiTitleActive'
                els[i].style.animationDuration = '200ms'
                setTimeout(()=>{
                  els[i].classList.add('active')
                }, 180)
              }
            }, 100)
          })
        }, 360)
      },360)

    }

  private initTimeline() {
    this.headerSubTitle = this.item.title
    this.timeline = document.getElementById('timeline');
    let timelineWidth = 0;
    for(let i = 0; i < this.item.poi.length; i++){
      let bullet = document.createElement('div')
      let left = window.innerWidth / 2 * i;

      if(i == this.lastOrder){
        bullet.style.left = left - 9 + "px"
        bullet.style.top = "0"
        bullet.style.backgroundColor = environment.themeColorValues[this.theme]
        bullet.classList.add('active')
      }
      else{
        bullet.style.top = '8px'
        bullet.style.left = left + 'px'
      }
      bullet.classList.add('poi-bullet', 'flex-align-center');
      bullet.style.borderColor = environment.themeColorValues[this.theme]
      // bullet.addEventListener('click', ()=>{
      //   this.navigator(i)
      // })

      let span = document.createElement('span');
      span.classList.add('poi-slide-number')
      span.innerHTML = `${i+1}`;
      bullet.append(span);
      this.timeline.append(bullet)
    }
    timelineWidth = ((window.innerWidth / 2) * this.item.poi.length - (window.innerWidth / 2) + 15);
    this.timeline.style.width = timelineWidth + 'px';
    let timelineContainer = document.getElementsByClassName('timeline-container') as HTMLCollectionOf<HTMLElement>

    if(this.slideOptsPoi.initialSlide == 0 ){
      timelineContainer[0].style.left = (window.innerWidth / 2) - 15 + 'px'
    }
    else if(this.slideOptsPoi.initialSlide == 1 ){
      timelineContainer[0].style.left = -15 + 'px'
    }
    else{
      let vw50 = -window.innerWidth / 2
      timelineContainer[0].style.left = `${(vw50 * this.slideOptsPoi.initialSlide - 1) - vw50 - 15}px`
    }
  }
}
