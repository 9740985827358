import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../../../services/utils.service";
import {CrudService} from "../../../services/crud.service";
import {ActivatedRoute} from "@angular/router";
import {FormControl} from "@angular/forms";
import {combineLatest, Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
})
export class SearchListComponent implements OnInit {
  media = environment.data.media
  amountItems = 1
  @Input() theme: any;
  items: any[] = [];
  private the_id: any;
  private general: any;
  searchTerm: string;
  constructor(public utilsService: UtilsService, private crudService: CrudService) {}

  ngOnInit() {
    this.theme = this.utilsService.themes.find(t => t.the_tag == 'search');
    this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['general', '1'], environment.apiKey).then(res =>{
      this.general = JSON.parse(res[0].con_text)
    })
    this.items = this.utilsService.routes
  }

  routerLink(item, b: boolean) {
    let url = `theme/${item.the_id}/detail/${item.rou_id}`
    this.utilsService.routerLink(url, b)
  }

  getInformationByItem(item: any) {
    return `${this.general?.durationText} ${item.rou_duration} ${this.general?.durationUnitText} ${this.general?.distanceText} ${item.rou_distance}${this.general?.distanceUnitText}`
  }


  toggleDisplayHomeIcon(b: boolean) {
    let logo = document.getElementById('home-button')
    if(b){
      logo.classList.add('d-none')
    }
    else{
      logo.classList.remove('d-none')
    }
  }
}
