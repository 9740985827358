import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(private http: HttpClient) {}

  // Setup request voor RESTApi met de SQL database GHK, tabel 'app_user'
  private async request(method: string, url: string, data?: any, headers?: any){
    let setupHeaders;
    if(headers){
      setupHeaders = headers
    }
    else{
      setupHeaders = {
        ContentType: 'application/json'
      }
    }
    const result = this.http.request(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body',
      headers: setupHeaders
    })

    return new Promise((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  setupHeaders(){
    return {
      headers: new HttpHeaders({
        Accept: 'text/plain',
        ContentType: 'text/plain'
      }),
    }
  }

  allData(url: string, key: string): any{
    return this.request('GET', `${environment.apiUrlNode}/${url}.php?key=${key}`);
  }

  singleData(url: string, typeId: string, id: string | number, key: string):any{
    return this.request('GET', `${environment.apiUrlNode}/${url}.php?key=${key}&${typeId}=${id}`);
  }

  singleDataById(url: string, typeId: string, id: number, key: string):any{
    return this.request('GET', `${environment.apiUrlNode}/${url}.php?key=${key}&${typeId}=${id}`);
  }

  createData(url: string, data: any, key: string) {
    return this.request('POST', `${environment.apiUrlNode}/${url}.php?key=${key}`, data);
  }

  editData(url: string, data: any, key: string){
    return this.request('PUT', `${environment.apiUrlNode}/${url}.php?key=${key}`, data);
  }

  deleteData(url: string, typeId: string, id: number, key: string ){
    return this.request('DELETE', `${environment.apiUrlNode}/${url}.php?key=${key}&${typeId}=${id}`);
  }
  deleteDataWithTwoConditions(url: string, fields: string[], values: (string | any)[], key: string ){
    let getString = ''
    for(let i = 0; i < fields.length; i++){
      getString += `&${fields[i]}=${values[i]}`
    }
    let lan_id = '1'
    getString += `&lan_id=${lan_id}`
    return this.request('DELETE', `${environment.apiUrlNode}/${url}.php?key=${key}${getString}`);
  }

  getKpiKey(url: string, key: string) {
    return this.request('GET', `${environment.apiUrlNode}/${url}/?key=${key}`);
  }

  singleDataWithTwoCondition(url: string, fields: string[], values: (string | any)[], apiKey: string):any {
    let getString = ''
    for(let i = 0; i < fields.length; i++){
      getString += `&${fields[i]}=${values[i]}`
    }
    let lan_id = '1'
    getString += `&lan_id=${lan_id}`
    return this.request('GET', `${environment.apiUrlNode}/${url}.php?key=${apiKey}${getString}`);
  }
}
