import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {UtilsService} from "../../services/utils.service";
import {CrudService} from "../../services/crud.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})

export class ListComponent implements OnInit {
  media = environment.data.media
  amountItems = 1
  @Input() theme: any;
  items = [];
  private the_id: any;
  private general: any;
  constructor(public utilsService: UtilsService, private crudService: CrudService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.the_id = this.route.snapshot.paramMap.get('the_id');
    if(!this.the_id){
      this.the_id = '6'
    }
    for(let item of this.utilsService.routes){
      if(item.the_id == this.the_id){
        this.items.push(item);
      }
    }
    this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['general', '1'], environment.apiKey).then(res =>{
      this.general = JSON.parse(res[0].con_text)
    })
  }

  routerLink(item, b: boolean) {
    let url = `theme/${item.the_id}/detail/${item.rou_id}`
    this.utilsService.routerLink(url, b)
  }

  getInformationByItem(item: any) {
    return `${this.general?.durationText} ${item.rou_duration} ${this.general?.durationUnitText} ${this.general?.distanceText} ${item.rou_distance}${this.general?.distanceUnitText}`
  }

  convertJSONtoString(con_text: any) {
    return JSON.parse(con_text).rou_name
  }
}
