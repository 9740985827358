import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../../../services/utils.service";
import {CrudService} from "../../../services/crud.service";

@Component({
  selector: 'filter-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss'],
})

export class FilterFavoriteComponent implements OnInit {
  theme:string;
  private id: string;
  private el: HTMLElement;
  imageUrl = 'assets/icon/filters/tholen_app_filter_favorite.png';
  paragraph : string;
  isFavorite: boolean = false;
  constructor(private route: ActivatedRoute, private utilsService: UtilsService, private crudService: CrudService) { }

  ngOnInit() {
    this.paragraph = this.utilsService.generalText.filters.favorites.saveAsFavorite
    this.el = document.getElementById('filter-favorite')
    let the_id = this.route.snapshot.paramMap.get('the_id')
    if(!the_id){
      the_id = '6'
    }
    this.theme = this.utilsService.themes.find(t => t.the_id == the_id).the_tag
    this.id = this.route.snapshot.paramMap.get('id')
    this.getImageStatus();
  }

  myFavorite() {
    if(!this.loopFavorites()){
      let fav = {
        rou_id: this.id,
      }
      this.utilsService.favorites.push(fav);
      localStorage.setItem('favorites', JSON.stringify(this.utilsService.favorites))
      this.isFavorite = true;
      this.imageUrl = 'assets/icon/filters/tholen_app_filter_favorite_active.png';
      this.paragraph = this.utilsService.generalText.filters.favorites.savedAsFavorite
    }
    else{
      this.utilsService.favorites.forEach((element,index)=>{
        if(element.rou_id == this.id) this.utilsService.favorites.splice(index,1);
      });
      localStorage.setItem('favorites', JSON.stringify(this.utilsService.favorites))
      this.isFavorite = false;
      this.imageUrl = 'assets/icon/filters/tholen_app_filter_favorite.png';
      this.paragraph = this.utilsService.generalText.filters.favorites.saveAsFavorite
    }
  }

  private loopFavorites() {
    let favs = JSON.parse(localStorage.getItem('favorites'))
    if(favs){
      for(let fav of favs)
      {
        if(fav.rou_id == this.id){
          return true
        }
      }
    }
    return false;
  }

  getImageStatus() {
    if(this.loopFavorites()){
      this.paragraph = this.utilsService.generalText.filters.favorites.savedAsFavorite
      this.imageUrl = "assets/icon/filters/tholen_app_filter_favorite_active.png"
    }
    else{
      this.paragraph = this.utilsService.generalText.filters.favorites.saveAsFavorite
      this.imageUrl = 'assets/icon/filters/tholen_app_filter_favorite.png';
    }
  }
}
