import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
// import * as mapboxgl from "mapbox-gl"
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import {UtilsService} from "../../../../services/utils.service";
import {CrudService} from "../../../../services/crud.service";
import {AppComponent} from "../../../../app.component";



@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  media = environment.data.media
  dummyMap: string;
  page: string;
  map!: mapboxgl.Map;
  permissionToFly: boolean;
  @Output() typeFilter = new EventEmitter<any>()
  style = 'mapbox://styles/dimaginecreativeagency/cl7ei0553001h14qw05qte7l3';
  zoomMap: number=16.0;
  lat = 51.532241;
  long = 4.224544;
  start = [this.lat, this.long];
  data = {
    counter: 0,
    text: '',
    oldCoor: [0,0],
    newCoor: [0,0],
    id: '',
    marker: {},
  }
  coordinates = [
    {
      coordinate: '4.869561%2C51.654444%3B4.869561%2C51.654444%3B4.86768%2C51.654531%3B4.86757%2C51.655134%3B4.867637%2C51.655623%3B4.868705%2C51.655508%3B4.868598%2C51.655056%3B4.869511%2C51.654914%3B4.869562%2C51.654439'
    }
  ]
  //https://docs.mapbox.com/playground/directions/
  // coordinations = '4.869548%2C51.654429%3B4.867824%2C51.656294%3B4.862463%2C51.656763%3B4.862404%2C51.655286%3B4.870532%2C51.654757%3B4.869558%2C51.654433'
  routes =[{
    coord: []
  }]
  private marker: HTMLDivElement;
  private reqAnimation: number;
  count = 0;
  private markerMapboxGl: any;
  error: any;
  msg: string;
  private theme: any;
  private the_id: string;
  item: any;
  private poiMarkers: any = [];
  success: boolean = false;
  private poiOrder: number = 0;
  crudMsg: any = '';
  private id: string;
  private parking: any;
  private reqFlyTo: number;
  public trigger: number = 1;
  private markers: HTMLCollectionOf<HTMLElement>;
  private currentMarker: HTMLElement;
  private collisionMarker: HTMLElement;
  private checkAllMarkers: boolean = true;
  private intervalNavigation: number;
  private intervalFlyTo: number;
  private intervalCollision: number;
  activeMarker = {
    id: '',
    order: '',
    media: '',
    title: '',
    subTitle: this.utilsService.generalText.poiCardSubtitle,
    show: false,
    nearby: false
  };
  private cardInfo: HTMLElement;
  private hitMarker: boolean = false;
  constructor(private route:ActivatedRoute,
              private geolocation: Geolocation,
              public utilsService: UtilsService,
              private crudService: CrudService) { }

  ngOnInit() {
    this.parking = JSON.parse(localStorage.getItem('parking'))
    this.item = JSON.parse(localStorage.getItem('route'))
    this.the_id = this.route.snapshot.paramMap.get('the_id')
    if(!this.the_id){
      this.the_id = '6'
    }
    this.id = this.route.snapshot.paramMap.get('id')
    this.theme = this.utilsService.getThemeTag(this.the_id)
    this.cardInfo = document.getElementById('poi-mat-card')
    this.page = this.route.snapshot.paramMap.get('page')
    localStorage.setItem('flyToGo', '0')
    this.geolocation.getCurrentPosition().then(resp => {
      this.utilsService.currentLat = resp.coords.latitude;
      this.utilsService.currentLng = resp.coords.longitude;
      if (!this.marker) {
        // add turn instructions here at the end
        this.marker = document.createElement('div');
        this.marker.className = 'current-location-marker'
        this.marker.id = 'current-location-marker'
        this.markerMapboxGl = new mapboxgl.Marker(this.marker)
        this.markerMapboxGl.setLngLat([Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)]).addTo(this.map)
      }
      else {
        this.markerMapboxGl.setLngLat([Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)])
      }
      this.initMap()
    })
  }

  ngOnDestroy(){
    clearInterval(this.intervalNavigation)
    clearInterval(this.intervalCollision)
    if(this.parking){
      localStorage.removeItem('parking')
    }
  }

  initMap() {
    if(this.parking){
      this.map = new mapboxgl.Map({
        accessToken: environment.mapboxAPIKey,
        container: 'route-map',
        style: this.style,
        zoom: this.zoomMap,
        center: JSON.parse(this.parking.inp_long_lat),
        scrollZoom      : false,
        boxZoom         : false,
        doubleClickZoom : false
      })
      // Add Marker on Click
      this.buildMap()
    }
    else{
        this.map = new mapboxgl.Map({
          accessToken: environment.mapboxAPIKey,
          container: 'route-map',
          style: this.style,
          zoom: this.zoomMap,
          center: [Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)]
        })
        this.coordinates = JSON.parse(this.item.rou_coordinations)
        // Add Marker on Click
        this.buildMap()
    }
  }

  private buildMap() {
    // this.map.scrollZoom.disable();
    // this.map.touchZoomRotate.disable();
    // this.map.doubleClickZoom.disable();
    this.map.on('load', (event) => {
      this.map.resize()
      // make an initial directions request that
      // starts and ends at the same location
      this.getRoute(this.start);
      // Add starting point to the map
      this.map.addLayer({
        id: 'point-route',
        type: 'circle',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: this.start
                }
              }
            ]
          }
        },
        paint: {
          'circle-radius': 10,
          'circle-color': this.utilsService.getColorCode(this.the_id)
        }
      });
      if (this.parking) {
        let marker = document.createElement('div')
        let img = document.createElement('img')
        marker.className = 'marker-map-overview ion-no-margin ion-text-center'
        img.src = environment.data.media.logoMarker
        marker.append(img);
        new mapboxgl.Marker(marker).setLngLat(JSON.parse(this.parking.inp_long_lat)).addTo(this.map)
      } else {
        let count = 1;
        for (let mar of this.item.poi) {
          if (mar) {
            let marker = document.createElement('div');
            marker.className = 'poi-marker flex-align-center'
            marker.id = 'poi-marker' + mar.poi_id;
            marker.innerHTML = `${count}`;
            marker.style.backgroundColor = environment.themeColorValues[this.theme]
            marker.addEventListener('click', (e) => {
              this.activeMarker.id = mar.poi_id
              this.activeMarker.order = mar.poi_order
              this.activeMarker.title = JSON.parse(mar.content[0][0].con_text).title
              this.activeMarker.media = mar.slider[0][0].med_media_url
              this.activeMarker.show = true;
              this.activeMarker.nearby = false;
              let markerActive = document.getElementsByClassName('poi-marker-active')
              if(markerActive.length > 0){
                this.removeActiveMarker(markerActive[0])
              }
              marker.classList.add('poi-marker-active')
              marker.classList.add('animation-marker-active')
              this.hitMarker = true
              setTimeout(()=>{
                marker.classList.remove('animation-marker-active')
              },400)
              this.cardInfo = document.getElementById('poi-mat-card')
              if (!this.cardInfo.classList.contains('poi-mat-card')) {
                this.cardInfo.classList.add('cardUp')
              }
            })
            new mapboxgl.Marker(marker).setLngLat(JSON.parse(mar.poi_lng_lat)).addTo(this.map)
            count++
          }

        }
      }
      this.geolocation.getCurrentPosition().then(resp => {
        this.data.oldCoor = [Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)]
        this.data.counter = this.data.counter + 1;
        this.utilsService.currentLat = resp.coords.latitude;
        this.utilsService.currentLng = resp.coords.longitude;
        let marker = document.getElementById('current-location-marker')
        if (!marker) {
          // add turn instructions here at the end
          this.markerMapboxGl = new mapboxgl.Marker(this.marker)
          this.markerMapboxGl.setLngLat([Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)]).addTo(this.map)
        }
        else {
          this.markerMapboxGl.setLngLat([Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)])
        }
        this.data.newCoor = [Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)]
      });
    })
      // this is where the code from the next step will go
  }

  async getRoute(end) {
    // make a directions request using cycling profile
    // an arbitrary start will always be the same
    // only the end or destination will change
    let count = 0;
    for(let coo of this.coordinates){
      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/walking/${coo.coordinate}?alternatives=true&continue_straight=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=${environment.mapboxAPIKey}`,
        { method: 'GET' }
      );
      const json = await query.json();
      const data = json.routes[0];
      const route = data.geometry.coordinates;
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: route
        }
      };
      // if the route already exists on the map, we'll reset it using setData
      if (this.map.getSource('route')) {
        this.map.getSource('route').setData(geojson);
      }
      // otherwise, we'll make a new request
      else {
        this.map.addLayer({
          id: 'route' + count,
          type: 'line',
          source: {
            type: 'geojson',
            data: geojson
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': this.utilsService.getColorCode(this.the_id),
            'line-width': 5,
            'line-opacity': 1
          }
        });
      }
      count = count + 1
    }
    setTimeout(()=>{
      this.utilsService.disappearSplashScreen()
    }, 3000)

    // this.intervalNavigation = setInterval(()=>{
    //   this.startNavigation()
    // }, 3000)
    this.intervalNavigation = setInterval(()=>{
      this.startNavigation()
    }, 3000)
    this.intervalCollision = setInterval(()=>{
      this.collisionChecker()
    }, 3000)
  }

  async startNavigation(){
    let options = {timeout: 3000, enableHighAccuracy: true, maximumAge: 3600};
    this.geolocation.getCurrentPosition(options).then(resp => {
      this.data.oldCoor = [Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)]
      this.data.counter = this.data.counter + 1;
      this.utilsService.currentLat = resp.coords.latitude;
      this.utilsService.currentLng = resp.coords.longitude;
      let marker = document.getElementById('current-location-marker')
      if (!marker) {
        // add turn instructions here at the end
        this.markerMapboxGl = new mapboxgl.Marker(this.marker)
        this.markerMapboxGl.setLngLat([Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)]).addTo(this.map)
      }
      else {
        this.markerMapboxGl.setLngLat([Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)])
      }
      this.data.newCoor = [Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)]
      // let inter = setInterval(()=>{
      //     this.data.count = count
      //     count = count - 1
      //     if(count == 0){
      //       clearInterval(inter)
      //     }
      //   },1000)
      // if(!this.parking){
      //
      //   let aroundPoi = false;
      //   for(let poi of this.item.poi){
      //     this.count = this.count + 1;
      //     let coor = JSON.parse(poi.poi_lng_lat)
      //     let distance = this.getDistanceFromLatLonInKm(this.utilsService.currentLat, this.utilsService.currentLng, coor[1], coor[0])
      //     if (distance <= parseFloat(poi.poi_radius)) {
      //       this.msg = `Poi # ${poi.poi_order}`
      //       this.poiOrder = Number(poi.poi_order);
      //       let markerActive = document.getElementsByClassName('poi-marker-active')
      //       if(markerActive.length > 0){
      //         markerActive[0].classList.remove('poi-marker-active')
      //       }
      //       let marker = document.getElementById('poi-marker' + poi.poi_id);
      //       marker.classList.add('poi-marker-active')
      //       this.utilsService.currentUser.route_progress.pro_last_poi_order = poi.poi_order
      //       this.crudService.editData('ta_route_progress', JSON.stringify(this.utilsService.currentUser.route_progress), environment.apiKey).then(res => {
      //         if(res){
      //           this.crudMsg = 'Succes progressie opgeslagen in DB!'
      //           setTimeout(()=>{
      //             this.crudMsg = '';
      //           }, 2000)
      //           this.reqAnimation = setTimeout(() => {
      //             this.startNavigation()
      //           },3000)
      //         }
      //       })
      //     }
      //     else{
      //
      //       this.reqAnimation = setTimeout(() => {
      //         this.startNavigation()
      //       },3000)
      //     }
      //
      //   }
      //   if(!aroundPoi){
      //   if(this.poiOrder == Number(this.utilsService.currentUser.route_progress.pro_last_poi_order) + 1){
      //     this.utilsService.currentUser.route_progress.pro_last_poi_order = Number(this.utilsService.currentUser.route_progress.pro_last_poi_order) + 1
      //     this.crudService.editData('ta_route_progress', JSON.stringify(this.utilsService.currentUser.route_progress), environment.apiKey).then(res => {
      //       if(res){
      //         this.crudMsg = 'Succes progressie opgeslagen in DB!'
      //         setTimeout(()=>{
      //           this.crudMsg = '';
      //         }, 2000)
      //       }
      //     })
      //   }
      //   this.msg = 'Geen POI in de buurt'
      //   this.success = false;
      //   }
      // }
    });
  }

  flyTo(){
    this.map.flyTo({
      zoom: this.zoomMap,
      center: [Number(this.utilsService.currentLng), Number(this.utilsService.currentLat)]
    })
  }

  getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    let R = 6371; // Radius of the earth in km
    let dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    let dLon = this.deg2rad(lon2-lon1);
    let a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = R * c; // Distance in km
    return Math.round(d * 100) / 100;
  }

  deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  collisionChecker() {
    if(!this.markers) {
      this.markers = document.getElementsByClassName('poi-marker') as HTMLCollectionOf<HTMLElement>
    }
    if(!this.currentMarker){
      this.currentMarker = document.getElementById('current-location-marker')
    }
    if(!this.checkAllMarkers){
      if(!this.hitDetection(this.collisionMarker.getBoundingClientRect(), this.currentMarker.getBoundingClientRect())){
        this.data.text = 'no hit'
        this.checkAllMarkers = true
      }
      else{
        this.data.text = 'hit'
      }
    }
    if(this.checkAllMarkers){
      for(let mar of this.markers){
        if(this.hitDetection(mar.getBoundingClientRect(), this.currentMarker.getBoundingClientRect())){
          this.hitMarker = true
          this.collisionMarker = mar
          this.checkAllMarkers = false
          let marId = mar.id.split('poi-marker')
          let poi = this.item.poi.find(p => p.poi_id == marId[1])
          this.msg = `Poi # ${poi.poi_order}`;
          this.poiOrder = Number(poi.poi_order);
          let markerActive = document.getElementsByClassName('poi-marker-active')
          if(markerActive.length > 0){
            markerActive[0].classList.remove('poi-marker-active')
            markerActive[0].classList.add('animation-marker-deactive')
            setTimeout(()=>{
              markerActive[0].classList.remove('animation-marker-deactive')
            },400)
          }
          let marker = document.getElementById('poi-marker' + poi.poi_id);
          marker.classList.add('poi-marker-active')
          markerActive[0].classList.add('animation-marker-active')
          setTimeout(()=>{
            markerActive[0].classList.remove('animation-marker-active')
          },400)
          this.activeMarker.id = poi.poi_id
          this.activeMarker.order = poi.poi_order
          this.activeMarker.title = JSON.parse(poi.content[0][0].con_text).title
          this.activeMarker.media = poi.slider[0][0].med_media_url
          this.activeMarker.show = true;
          this.activeMarker.nearby = true;
          this.cardInfo = document.getElementById('poi-mat-card')
          this.cardInfo.classList.add('cardUp')
          this.data.marker = this.activeMarker
          let progress = {
            id: poi.poi_id,
            rou_id: this.item.rou_id,
            progress: poi.poi_order
          }
          localStorage.setItem('routeProgress', JSON.stringify(progress))
          // this.utilsService.currentUser.route_progress.pro_last_poi_order = poi.poi_order
          // this.crudService.editData('ta_route_progress', JSON.stringify(this.utilsService.currentUser.route_progress), environment.apiKey).then(res => {
          //   if(res){
          //     this.crudMsg = 'Succes progressie opgeslagen in DB!'
          //     setTimeout(()=>{
          //       this.crudMsg = '';
          //     }, 2000)
          //     this.reqAnimation = setTimeout(() => {
          //       this.startNavigation()
          //     },3000)
          //   }
          // })
          // break
        }
        else{
          this.data.text = 'no hit'
        }
      }

      if(!this.hitMarker){
        let markerActive = document.getElementsByClassName('poi-marker-active')
        if(markerActive.length > 0){
          this.closeCard()
          this.removeActiveMarker(markerActive[0])
        }
      }
    }
  }

  private hitDetection(a, b) {
      function getPositions( elem ) {
        let width, height;
        width = elem.width / 2;
        height = elem.height;
        return [ [ elem.left, elem.left + width ], [ elem.top, elem.top + height ] ];
      }

      function comparePositions( p1, p2 ) {
        let r1, r2;
        r1 = p1[0] < p2[0] ? p1 : p2;
        r2 = p1[0] < p2[0] ? p2 : p1;
        return r1[1] > r2[0] || r1[0] === r2[0];
      }

      let pos1 = getPositions( a ),
          pos2 = getPositions( b );
      return comparePositions( pos1[0], pos2[0] ) && comparePositions( pos1[1], pos2[1] );
  }

  routerLink() {
    let progress = {
      id: this.activeMarker.id,
      progress: this.activeMarker.order
    }
    localStorage.setItem('routeProgress', JSON.stringify(progress))
    let id = this.route.snapshot.paramMap.get('id')
    let the_id = this.utilsService.themes.find(t => t.the_tag == 'route').the_id;
    this.theme = this.utilsService.getThemeTag(the_id)
    this.typeFilter.emit('poi')
  }

  getThemeColor() {
    return environment.themeColorValues[this.theme];
  }

  closeCard() {
    this.hitMarker = false;
    let markerActive = document.getElementsByClassName('poi-marker-active')
    if(markerActive.length > 0){
      this.removeActiveMarker(markerActive[0])
    }
    this.cardInfo.classList.add('cardDown')
    setTimeout(()=>{
      this.cardInfo.classList.remove('cardUp')
      this.cardInfo.classList.remove('cardDown')
    }, 400)
    setTimeout(()=>{
      this.activeMarker.show = false
    },1800)
  }

  private removeActiveMarker(markerActiveElement: Element) {
    markerActiveElement.classList.add('animation-marker-deactive')
      setTimeout(()=>{
        markerActiveElement.classList.remove('animation-marker-deactive')
        markerActiveElement.classList.remove('poi-marker-active')
      },400)
  }
}
