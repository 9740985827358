import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private location: Location) { }

  public startSaveHistory():void{
    this.router.events.subscribe((event) =>{
      if(event instanceof NavigationEnd){
        if(event.urlAfterRedirects.substring(1) != this.history[this.history.length-1]){
          this.history.push(event.urlAfterRedirects.substring(1))
        }
      }
    })
  }


  public getHistory(): string[] {
    return this.history
  }

  public goBack():void{
    let url = this.history[this.history.length-2];
    this.history.pop();
    if(this.history.length > 0){
      this.routerLink(url)
    }
    else{
      this.routerLink('/')
    }
  }

  public getPreviousUrl(): string{
    if(this.history.length > 0){
      return this.history[this.history.length - 2]
    }
    return "";
  }

  private routerLink(url){
    this.router.navigateByUrl(url, {
      replaceUrl: true
    })
  }
}
