import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from "@ionic/angular";
import {MapBoxComponent} from "../components/map-box/map-box.component";
import {ListComponent} from "../components/list/list.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {LogoMarkerComponent} from "../components/logo-marker/logo-marker.component";
import {HeaderSubTitleComponent} from "../components/header-sub-title/header-sub-title.component";
import {HeaderComponent} from "../components/header/header.component";
import {SummaryItemComponent} from "../components/summary-item/summary-item.component";
import {SliderComponent} from "../components/slider/slider.component";
import {FilterMapComponent} from "../components/filters/map/map.component";
import {FilterFavoriteComponent} from "../components/filters/favorite/favorite.component";
import {FilterInformationComponent} from "../components/filters/information/information.component";
import {FilterAttractionsComponent} from "../components/filters/attractions/attractions.component";
import {FilterMoreComponent} from "../components/filters/more/more.component";
import {FilterAccessibilityComponent} from "../components/filters/accessibility/accessibility.component";
import {FilterCurrentLocationComponent} from "../components/filters/current-location/current-location.component";
import {FilterPoiComponent} from "../components/filters/poi/poi.component";
import {FilterRouteComponent} from "../components/filters/route/route.component";
import {FilterSearchComponent} from "../components/filters/search/search.component";
import {FilterStartRouteComponent} from "../components/filters/start-route/start-route.component";
import {FiltersComponent} from "../components/filters/filters.component";
import {FilterSpeakComponent} from "../components/filters/speak/speak.component";
import {MapComponent} from "../tab-bar/tab-routes/components/map/map.component";
import {PoiComponent} from "../tab-bar/tab-routes/components/poi/poi.component";
import {ReturnButtonComponent} from "../components/return-button/return-button.component";
import {SplashScreenComponent} from "../components/splash-screen/splash-screen.component";
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import {MapBoxDetailComponent} from "../components/map-box/map-box-detail/map-box-detail.component";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FilterStopRouteComponent} from "../components/filters/stop-route/stop-route.component";
import {SearchListComponent} from "../components/list/search-list/search-list.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {FavoriteListComponent} from "../components/list/favorite-list/favorite-list.component";
import {DialogComponent} from "../components/dialog/dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import { SearchPipe } from './search.pipe';
import { SafeResourceUrlPipe } from './safe-resource-url.pipe';
import {FlyToDirective} from "./flyto.directive";
import {MatCardModule} from "@angular/material/card";
import {MenuButtonComponent} from "../components/menu-button/menu-button.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {TabBarPage} from "../tab-bar/tab-bar.page";

@NgModule({
  declarations: [
    MapBoxComponent,
    DialogComponent,
    ListComponent,
    LogoMarkerComponent,
    HeaderSubTitleComponent,
    HeaderComponent,
    SummaryItemComponent,
    SliderComponent,
    FiltersComponent,
    FilterAccessibilityComponent,
    FilterAttractionsComponent,
    FilterCurrentLocationComponent,
    FilterFavoriteComponent,
    FilterInformationComponent,
    FilterMapComponent,
    FilterMoreComponent,
    FilterPoiComponent,
    FilterRouteComponent,
    FilterSearchComponent,
    FilterSpeakComponent,
    FilterStartRouteComponent,
    FilterStopRouteComponent,
    MapComponent,
    MapBoxDetailComponent,
    PoiComponent,
    ReturnButtonComponent,
    MenuButtonComponent,
    SplashScreenComponent,
    SearchListComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    FavoriteListComponent,
    SearchPipe,
    SafeResourceUrlPipe,
    FlyToDirective,
    TabBarPage
  ],
  imports: [
    CommonModule,
    IonicModule,
    DragDropModule,
    ClipboardModule,
    MatTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2SearchPipeModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule
  ],
  exports: [
    MapBoxComponent,
    MapBoxDetailComponent,
    DialogComponent,
    ListComponent,
    LogoMarkerComponent,
    HeaderSubTitleComponent,
    HeaderComponent,
    FiltersComponent,
    FilterAccessibilityComponent,
    FilterAttractionsComponent,
    FilterCurrentLocationComponent,
    FilterFavoriteComponent,
    FilterInformationComponent,
    FilterMapComponent,
    FilterMoreComponent,
    FilterPoiComponent,
    FilterRouteComponent,
    FilterSearchComponent,
    FilterSpeakComponent,
    FilterStartRouteComponent,
    FilterStopRouteComponent,
    SliderComponent,
    SearchListComponent,
    SummaryItemComponent,
    MapComponent,
    PoiComponent,
    ReturnButtonComponent,
    MenuButtonComponent,
    SplashScreenComponent,
    FavoriteListComponent,
    SafeUrlPipe,
    SafeResourceUrlPipe,
    TabBarPage
  ]
})
export class SharedModule { }
