import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../../../services/utils.service";
import {CrudService} from "../../../services/crud.service";
import {ActivatedRoute} from "@angular/router";
import {FormControl} from "@angular/forms";
import {combineLatest, Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.scss'],
})
export class FavoriteListComponent implements OnInit {
  media = environment.data.media
  amountItems = 1
  @Input() theme: any;
  items: any[] = [];
  private the_id: any;
  private general: any;
  constructor(public utilsService: UtilsService, private crudService: CrudService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.theme = this.utilsService.themes.find(t => t.the_tag == 'favorite');
    for(let favo of this.utilsService.favorites){
      this.crudService.singleData('ta_route','rou_id', favo.rou_id, environment.apiKey).then((res) => {
        if (res) {
          this.getContent(res)
        }
      })
    }

    this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['general', '1'], environment.apiKey).then(res =>{
      this.general = JSON.parse(res[0].con_text)
    })
  }

  private getContent(res) {
    for(let i = 0; i < res.length; i++){
      if(res[i].rou_active == '1'){
        let item = res[i];
        item.slider = []
        item.accessibility = []
        item.parking = []
        item.poi = []
        item.attractions = []
        item.content = []
        item.att_name = ""

        this.crudService.singleDataWithTwoCondition('ta_media', ['med_id_name', 'med_id_value'], ['rou_id', res[i].rou_id], environment.apiKey).then(med => {
          item.slider = med;
        })
        this.crudService.singleData('ta_accessibility', 'rou_id', res[i].rou_id, environment.apiKey).then(res3 => {
          if (res3) {
            let acc = []
            for (let j = 0; j < res3.length; j++) {
              this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['acc_id', res3[j].acc_id], environment.apiKey).then(res4 => {
                acc.push(res4[0])
                item.acc_name = item.acc_name + res4[0].con_text + ", "

              })
            }
            item.accessibility = acc;
          }
        })
        this.crudService.singleData('ta_parking', 'rou_id', res[i].rou_id, environment.apiKey).then((res7) => {
          if (res7) {
            item.parking = res7;
          }
        })
        this.crudService.singleData('ta_theme', 'rou_id', res[i].rou_id, environment.apiKey).then((res7) => {
          if (res7) {
            item.parking = res7;
          }
        })
        this.crudService.singleData('ta_poi', 'rou_id', res[i].rou_id, environment.apiKey).then(res11 => {
          for (let k = 0; k < res11.length; k++) {
            let poi = {
              content: [],
              slider: []
            }
            this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', "con_id_value"], ['poi_id', res11[k].poi_id], environment.apiKey).then(res2 => {
              poi.content.push(res2)
              this.crudService.singleDataWithTwoCondition('ta_media', ['med_id_name', "med_id_value"], ['poi_id', res11[k].poi_id], environment.apiKey).then(res3 => {
                poi.slider.push(res3)
                item.poi.push(poi)
              })
            })
          }
        })
        this.crudService.singleData('ta_attraction', 'rou_id', res[i].rou_id, environment.apiKey).then((res5) => {
          if (res5) {
            let attr = []
            for (let l = 0; l < res5.length; l++) {
              this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['att_id', res5[l].att_id], environment.apiKey).then(res6 => {
                attr.push(res6[0])
                item.att_name = item.att_name + res6[0].con_text + ", "
              })
            }
            item.attractions = attr;
          }
        })

        this.crudService.singleData('ta_theme', 'rou_id', res[i].rou_id, environment.apiKey).then(theme =>{
          item.the_id = theme[0].the_id
          item.the_color = theme[0].the_color
          item.the_icon_url = theme[0].the_icon_url
          item.the_tag = theme[0].the_tag
          this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['rou_id', res[i].rou_id], environment.apiKey).then(res2 => {
            item.content = res2[0]
            item.rou_name = this.convertJSONtoString(res2[0].con_text)
            this.items.push(item)
          })
        })
      }
    }
  }

  routerLink(item, b: boolean) {
    let url = `theme/${item.the_id}/detail/${item.rou_id}`
    this.utilsService.routerLink(url, b)
  }

  getInformationByItem(item: any) {
    return `${this.general?.durationText} ${item.rou_duration} ${this.general?.durationUnitText} ${this.general?.distanceText} ${item.rou_distance}${this.general?.distanceUnitText}`
  }

  convertJSONtoString(con_text: any) {
    return JSON.parse(con_text).rou_name
  }
}
