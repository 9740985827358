import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: 'filter-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class FilterInformationComponent implements OnInit {
  filter: string;
  theme: string;
  data: any;
  @Output() typeFilter = new EventEmitter<any>();
  private id: string;
  constructor(private route: ActivatedRoute, public utilsService: UtilsService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('the_id')
    this.theme = this.utilsService.getThemeTag(this.id)
    this.filter = this.route.snapshot.paramMap.get('filter')
  }

  routerLink(){
    this.typeFilter.emit('information')
  }


  getThemeColor(){
    if(this.filter == 'information'){
      return this.utilsService.themes.find(t => t.the_id == this.id).the_color
    }
  }
}
