import {Component, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {UtilsService} from "./services/utils.service";
import {ActivatedRoute} from "@angular/router";
import {NavigationService} from "./services/navigation.service";
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import {CrudService} from "./services/crud.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  media = environment.data.media;
  theme: any;
  private static staticTheme: string;
  private static staticShowSplashscreen: boolean = false;
  constructor(
    public utilsService: UtilsService,
    private navigationService: NavigationService,
    private crudService: CrudService) {
  }

  ngOnInit(): void {
    let stringGeneral = {"filters": { "favorites": {"saveAsFavorite": "Bewaren als favoriet","savedAsFavorite": "Bewaard als favoriet"    },    "startRoute": {      "startRoute": "Start route",      "resumeRoute": "Terug naar route"    },    "information": {      "title": "Route informatie"    },    "mapBox": {      "title": "Kaartweergave"    },    "poi": {      "title": "Bezienswaardigheden"    },    "stopRoute": {      "title": "Beëindig route"    }  },  "filterMsg":"Resultaten op basis van uw filters",  "dialog": {"title": "Route annuleren", "subTitle": "Weet u het zeker dat u de route wilt afsluiten?","cancel": "Nee", "ok": "Ja"},  "experience":{"title":"Parelroutes","subTitle":"Beleef eiland Tholen"},  "inspiring":{"title":"Inspirerend","subTitle":"Wat je echt moet zien"},  "education":{"title":"Educatief","subTitle":"Voor scholen"},  "informative":{"title":"Informerend","subTitle":"Praktisch om te weten"},  "route":{ "title":"Route",    "routeInfoTitle":"Route informatie",    "linkToMap":"Toon locatie <br> op kaart",    "linkToCopy":"Kopieer adres",    "parkingTitle":"Parkeren",    "linkHasCopied": "Adres is gekopieerd"  },  "durationText":"Duur: +/- ",  "distanceText":"Afstand: ",  "distanceUnitText":"km",  "durationUnitText":"uur",  "accessibilityText":"Toegankelijk: ", "ratingText":"Beoordeling: ", "interessesText":"Interesses: ",  "poiCardSubtitle": "U nadert",  "poiCardReadButton": "Lees meer",  "poiCardCloseButton": "Sluiten"}
    let data = {
      status: 'new'
    }
    this.crudService.createData('ta_visitors', JSON.stringify(data),environment.apiKey).then(res=>{})
    this.utilsService.getTextByLanguage()
    this.navigationService.startSaveHistory()
    this.utilsService.getData()
    this.getOsData()
  }

  private getOsData() {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os;

    if (iosPlatforms.indexOf(platform) !== -1) {
      if(window.innerHeight > 1700){

      }
    }
  }

  public static activateSplashscreen(theme, bool){
    this.staticTheme = theme;
    this.staticShowSplashscreen = bool;
  }

  showSplashscreen(){
    return AppComponent.staticShowSplashscreen
  }

  getTheme(){
    return AppComponent.staticTheme
  }

  static checkSplashScreen() {
    return AppComponent.staticShowSplashscreen
  }

  static activateAnimation(theme){
    if(theme.the_icon_gif_url){
      theme.the_icon_url = theme.the_icon_gif_url
    }
    setTimeout(()=>{
      this.createSplashscreen(theme)
    })
  }

  static createSplashscreen(theme) {
    let splashScreen = document.createElement('div');
    let body = document.getElementsByTagName('app-tab-main')[0];
    if(!body){
      body = document.getElementsByTagName('app-tab-search')[0]
    }
    let tabBar = document.getElementsByTagName('ion-tab-bar')[0];
    let homeButton = document.getElementById('home-button');
    tabBar.classList.add('hidden');
    homeButton.classList.add('hidden');
    // let homeButton =
    splashScreen.classList.add('splash-screen');
    splashScreen.style.backgroundColor = `${theme.the_color}`;
    body.append(splashScreen);
    let el = document.getElementById(`menu-item-${theme.the_id}`);

    // el.style.position = 'absolute'
    el.style.left = `25vw`
    el.style.top = `-12.5vh`
    el.style.zIndex = `99999`
    el.style.color = `white!important`
    el.children[1].classList.add('color-white')
    el.children[2].classList.add('color-white')
    el.classList.add('filter-white-image')
    setTimeout(()=>{
      AppComponent.activateSplashscreen(theme, true)
    },130)

  }

  routerLink() {
    this.utilsService.routerLink('theme/main', true )
  }
}
