import {Directive, EventEmitter, Input, Output, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[flyStart]',
})
export class FlyToDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
  @Input() flyStart: number;
  @Output('flyToTrigger') initEvent: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    setTimeout(() => this.initEvent.emit(), 10);
  }

  ngOnChanges(){
    if (this.flyStart > 0) {
      setTimeout(() => this.initEvent.emit(), 10);
    }
  }
}
