import {
  Component,ElementRef, EventEmitter,
  Input, OnDestroy,
  OnInit, Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() theme: string
  @Input() page: string
  @Output() typeFilter = new EventEmitter<any>();
  @ViewChild('filterTemplate', {read: ViewContainerRef}) filterTemplate: ViewContainerRef;
  @ViewChild("parent") private parentRef: ElementRef<HTMLElement>;
  filters = [];
  data;
  filterContainerHeight = 45;
  filterComponentNames = [];
  components: any[];
  filter: string;
  constructor(public dom: DomSanitizer) { }

  ngOnInit() {
    this.components = [];
  }

  ngOnDestroy() {
    this.components = [];
  }

  changeComponent($event: any) {
    this.typeFilter.emit($event);
  }
}
