import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import * as mapboxgl from "mapbox-gl"
import {UtilsService} from "../../services/utils.service";
import {Geolocation} from "@awesome-cordova-plugins/geolocation/ngx";

@Component({
  selector: 'app-map-box',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.scss'],
})
export class MapBoxComponent implements OnInit {
  media = environment.data.media
  dummyMap: string;
  page: string;
  map!: mapboxgl.Map;
  style = 'mapbox://styles/dimaginecreativeagency/cl7ei0553001h14qw05qte7l3';
  zoomMap: number = 9.5;
  lat = 51.57;
  long = 4.12;
  start = [this.lat, this.long];
  coordinations = '4.223447160294427%2C51.52997931302352%3B4.221335972481114%2C51.52936217818572%3B4.219786951232123%2C51.529526576491435%3B4.219430847264221%2C51.53044040449268%3B4.220689929154162%2C51.53130899444466%3B4.2195007962581315%2C51.53124174494178%3B4.218419766353605%2C51.53069187763157%3B4.217733234387055%2C51.53148954050042%3B4.21680920778897%2C51.53189226217003%3B4.216751976794285%2C51.5326992381174%3B4.217899802634179%2C51.533173624609134%3B4.218220120988775%2C51.53436288506518%3B4.219401922699092%2C51.534124415635404%3B4.220877210568773%2C51.535176594548375%3B4.221837419483393%2C51.53423121685964%3B4.219917001652902%2C51.5334559133903%3B4.221449280540838%2C51.532643251185306%3B4.221557383536009%2C51.53199742926694%3B4.2226315744865985%2C51.53275732466744%3B4.224402094872289%2C51.5336132259234%3B4.223852262403085%2C51.53225130393062%3B4.223445526414821%2C51.52997389565337'
  routes = [{
    coord: []
  }]
  @Input() item
  constructor(private route:ActivatedRoute,
              private geolocation: Geolocation,
              private utilsService: UtilsService) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.utilsService.createMap('overview')
    setTimeout(()=>{
      this.utilsService.disappearSplashScreen()
    },1000)
  }
}
