import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-logo-marker',
  templateUrl: './logo-marker.component.html',
  styleUrls: ['./logo-marker.component.scss'],
})
export class LogoMarkerComponent implements OnInit {
  @Input() number;
  media = environment.data.media
  constructor() { }

  ngOnInit() {}

}
