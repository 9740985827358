import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilsService} from "../services/utils.service";
import {environment} from "../../environments/environment";
import {CrudService} from "../services/crud.service";
import {AppComponent} from "../app.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-tab-bar',
  templateUrl: 'tab-bar.page.html',
  styleUrls: ['tab-bar.page.scss']
})
export class TabBarPage implements OnInit{
  media = environment.data.media
  routes: any[];
  theme: any;
  @Input() page: string;
  @Input() activePage: string;
  @Output() typeFilter = new EventEmitter<any>()

  constructor(public utilsService: UtilsService, private crudService: CrudService, private route: ActivatedRoute) {}

  checkAmountFavorite() {
    if(this.utilsService.favorites.length >= 100){
      return "99+"
    }
    else{
      return this.utilsService.favorites.length
    }
  }

  ngOnInit(): void {
    let tag = this.route.snapshot.paramMap.get('the_id');

    if(!tag){
      tag = '6'
    }

    this.theme = this.utilsService.themes.find(t => t.the_id == tag)


  }

  getTotalFavorites(){
    return this.utilsService.favorites.length
  }

  checkActiveRoute() {
    let route = JSON.parse(localStorage.getItem('route'));
    if(route != null){
      return true
    }
  }

  routerLink(url) {
    if(url == 'theme/routes'){
      let route = JSON.parse(localStorage.getItem('route'));
      this.utilsService.routerLink(`theme/routes/${route.rou_id}`, true)
    }
  }
}
