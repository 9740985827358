import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../../services/utils.service";
import {environment} from "../../../../environments/environment";
import {CrudService} from "../../../services/crud.service";

@Component({
  selector: 'filter-start-route',
  templateUrl: './start-route.component.html',
  styleUrls: ['./start-route.component.scss'],
})
export class FilterStartRouteComponent implements OnInit {
  data: any;
  media = environment.data.media
  theme: string;
  image: string;
  paragraph: string;
  private item: any;
  private id: string;
  constructor(private route: ActivatedRoute, private utilsService: UtilsService, private crudService: CrudService) { }

  ngOnInit() {
    this.paragraph = this.utilsService.generalText.filters.startRoute.startRoute
    this.image = 'assets/icon/filters/tholen_app_filter_start_route.png'
    this.item = JSON.parse(localStorage.getItem('route'))
    this.id = this.route.snapshot.paramMap.get('id')
    if(this.item){
      if(this.item.rou_id == this.id){
        this.paragraph = this.utilsService.generalText.filters.startRoute.resumeRoute
      }
    }
  }

  setRoute(){
    localStorage.setItem('route', JSON.stringify(this.utilsService.selectedRoute))
    this.image = this.media.routeStartGif;
    this.routerLink(700, this.id)
  }

  routerLink(timeout, id){
    let data = {
      rou_id: id
    }
    this.crudService.createData('ta_route_visitors', JSON.stringify(data), environment.apiKey).then(res=>{
    })
    setTimeout(()=>{
      this.utilsService.routerLink(`theme/routes/${id}`, true)
    }, timeout)
  }
}
