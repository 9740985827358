import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'filter-current-location',
  templateUrl: './current-location.component.html',
  styleUrls: ['./current-location.component.scss'],
})
export class FilterCurrentLocationComponent implements OnInit {
  @Output() flyTo = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {}

  flyToGo() {
    this.flyTo.emit();
    localStorage.setItem('flyToGo', '1')
  }
}
