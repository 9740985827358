import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "./navigation.service";
import {CrudService} from "./crud.service";
import mapboxgl from 'mapbox-gl';
import {AppComponent} from "../app.component";
import {Geolocation} from "@awesome-cordova-plugins/geolocation/ngx"; // or "const mapboxgl = require('mapbox-gl');"

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  data;
  selectedRoute: any;
  showHomeButton = false;
  generalText: any = [];
  themes: any = [];
  favorites: any = [];
  routes: any = [];
  content: any = [];
  filters: any = [];
  pages: any = [];
  filPag: any = [];
  currentUser: any;
  currentLat: number;
  currentLng: number;
  zoomMap = 16.0;
  themeSearchValue: string
  themeAccessValue: string
  themeAttractionValue: string
  openMenu: boolean = false;
  overviewMap!: mapboxgl.Map;
  detailMap!: mapboxgl.Map;
  routeMap!: mapboxgl.Map;
  showCurrentLocationFilter: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private crudService: CrudService
  ) {
    this.themeSearchValue = "";
    this.themeAccessValue = "";
    this.themeAttractionValue = "";
  }

  routerLink(url: string, save?: boolean) {
    this.themeSearchValue = '';
    this.themeAccessValue = "";
    this.themeAttractionValue = "";
    this.showCurrentLocationFilter = false
    this.showHomeButton = url != 'theme/main';
    this.router.navigateByUrl(url, {
      replaceUrl: true
    })
  }

  getThemeColor(item) {
    if (item) {
      return item.the_tag;
    }
  }

  returnRouterLink() {
    this.navigationService.goBack()
  }

  getTextByLanguage() {
    switch (localStorage.getItem('language')) {
      case "nl":
        this.data = environment.data.language.nl
        break;
      case "en":
        this.data = environment.data.language.nl
        break;
      case "de":
        this.data = environment.data.language.nl
        break;
      case "fr":
        this.data = environment.data.language.nl
        break;
      default:
        this.data = environment.data.language.nl
    }
  }

  getCurrentUrl() {
    return window.location.href;
  }

  getHeaderTitle(theme: string) {
    return this.data.general[theme].title
  }

  getData() {
    this.checkRefreshData()
  }

  getGeneralTextByHttpGet() {
    if (!localStorage.getItem('generalText')) {
      this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['general', '1'], environment.apiKey).then(res => {
        if (res) {
          localStorage.setItem('generalText', res[0].con_text)
          this.generalText = JSON.parse(res[0].con_text);
        }
      })
    } else {
      this.generalText = JSON.parse(localStorage.getItem('generalText'))
    }
  }

  getThemesByHttpGet() {
    if (!localStorage.getItem('themes')) {
      this.crudService.allData('ta_theme', environment.apiKey).then(res => {
        if (res) {
          localStorage.setItem('themes', JSON.stringify(res))
          this.themes = res;
        }
      })
    } else {
      this.themes = JSON.parse(localStorage.getItem('themes'))
    }
  }

  getThemeTag(the_id: string) {
    if(!the_id){
      the_id = '6'
    }
    return this.themes.find(s => s.the_id == the_id).the_tag;
  }

  getColorCode(the_id: string) {
    if(!the_id){
      the_id = '6'
    }
    return this.themes.find(s => s.the_id == the_id).the_color;
  }

  getFavoritesByHttpGet() {
    if (localStorage.getItem('favorites')) {
      this.favorites = JSON.parse(localStorage.getItem('favorites'))
    }
  }

  getPagFilByHttpGet() {
    if (!localStorage.getItem('pages')) {
      this.crudService.allData('ta_page', environment.apiKey).then(pages => {
        if (pages) {
          this.crudService.allData('ta_filter', environment.apiKey).then(filters => {
            if (filters) {
              this.crudService.allData('ta_fil_pag', environment.apiKey).then(filPags => {
                if (filPags) {
                  for (let pag of pages) {
                    let arr = []
                    for (let item of filPags) {
                      if (item.pag_id == pag.pag_id) {
                        arr.push(filters.find(f => f.fil_id == item.fil_id))
                      }
                    }
                    pag.filters = arr;
                  }
                  this.pages = pages;
                  localStorage.setItem('pages', JSON.stringify(this.pages))
                }
              })
            }
          })
        }
      })
    } else {
      this.pages = JSON.parse(localStorage.getItem('pages'))
    }
  }

  getContentByHttpGet() {
    if (!localStorage.getItem('content')) {
      this.crudService.singleData('ta_content', 'lan_id', '1', environment.apiKey).then(res => {
        if (res) {
          localStorage.setItem('content', JSON.stringify(res))
          this.content = res;
        }
      })
    } else {
      this.content = JSON.parse(localStorage.getItem('content'))
    }
  }

  getRoutesByHttpGet() {
    if (!localStorage.getItem('routes')) {
      this.crudService.allData('ta_route', environment.apiKey).then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (res[i].rou_active == '1') {
              let item = res[i];
              item.slider = []
              item.accessibility = []
              item.parking = []
              item.poi = []
              item.attractions = []
              item.content = []
              item.att_name = ""
              item.acc_name = ""
              this.crudService.singleDataWithTwoCondition('ta_media', ['med_id_name', 'med_id_value'], ['rou_id', res[i].rou_id], environment.apiKey).then(med => {
                if (med) {
                  item.slider = med;
                }
              })
              this.crudService.singleData('ta_accessibility', 'rou_id', res[i].rou_id, environment.apiKey).then(res3 => {
                if (res3) {
                  let acc = []
                  for (let j = 0; j < res3.length; j++) {
                    this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['acc_id', res3[j].acc_id], environment.apiKey).then(res4 => {
                      let i = 0;
                      for (let acc of res4) {
                        item.acc_name += res4[i].con_text + ", "
                      }
                      acc.push(res4)
                    })
                  }
                  item.accessibility = acc;
                }
              })
              this.crudService.singleData('ta_parking', 'rou_id', res[i].rou_id, environment.apiKey).then((res7) => {
                if (res7) {
                  item.parking = res7;
                }
              })
              this.crudService.singleData('ta_theme', 'rou_id', res[i].rou_id, environment.apiKey).then((res7) => {
                if (res7) {
                  item.theme = res7;
                }
              })
              this.crudService.singleData('ta_poi', 'rou_id', res[i].rou_id, environment.apiKey).then(res11 => {
                if (res11) {
                  let count = 0;
                  let arrPoi = []
                  let index;
                  for (let k = 0; k < res11.length; k++) {
                    index = k;
                    let tempPoi = {
                      rou_id: res[i].rou_id,
                      poi_order: res11[k].poi_order,
                      poi_id: res11[k].poi_id,
                      poi_lng_lat: res11[k].poi_long_lat,
                      poi_radius: res11[k].poi_radius,
                      poi_active: res11[k].poi_active,
                      content: [],
                      slider: []
                    }
                    this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', "con_id_value"], ['poi_id', res11[k].poi_id], environment.apiKey).then(res2 => {
                      if (JSON.parse(res2[0].con_text).title != null) {
                        count++;
                        tempPoi.content.push(res2)
                        this.crudService.singleDataWithTwoCondition('ta_media', ['med_id_name', "med_id_value"], ['poi_id', res11[k].poi_id], environment.apiKey).then(res3 => {
                          if (res3) {
                            tempPoi.slider.push(res3)
                            arrPoi.push(tempPoi)

                            if (arrPoi.length == res11.length) {
                              let pois = []
                              let poisTemp = []
                              for (let j = 0; j < arrPoi.length; j++) {
                                poisTemp.push(arrPoi.find(p => p.poi_order == String(j + 1)))
                              }
                              for (let poi of poisTemp) {
                                if (poi.poi_active == '1') {
                                  pois.push(poi)
                                }
                              }
                              for (let j = 0; j < pois.length; j++) {
                                pois[j].poi_order = j + 1
                              }
                              item.poi = pois
                              if (index == (res11.length - 1)) {
                                this.crudService.singleData('ta_attraction', 'rou_id', res[i].rou_id, environment.apiKey).then((res5) => {
                                  if (res5) {
                                    let attr = []
                                    for (let l = 0; l < res5.length; l++) {
                                      this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['att_id', res5[l].att_id], environment.apiKey).then(res6 => {
                                        attr.push(res6[0])
                                        item.att_name = item.att_name + res6[0].con_text + ", "
                                      })
                                    }
                                    item.attractions = attr;
                                  }
                                })

                                this.crudService.singleData('ta_theme', 'rou_id', res[i].rou_id, environment.apiKey).then(theme => {
                                  if (theme) {
                                    item.the_id = theme[0].the_id
                                    item.the_color = theme[0].the_color
                                    item.the_icon_url = theme[0].the_icon_url
                                    item.the_tag = theme[0].the_tag
                                    this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['rou_id', res[i].rou_id], environment.apiKey).then(res2 => {
                                      if (res2) {
                                        item.content = res2[0]
                                        item.rou_name = this.convertJSONtoString(res2[0].con_text)
                                        this.routes.push(item)

                                        if (res.length == this.routes.length) {
                                          localStorage.setItem('routes', JSON.stringify(this.routes))
                                        }
                                      }
                                    })
                                  }
                                })
                              }

                            }
                          }
                        })

                      }
                    })
                  }

                }
              })
            }
          }
        }
      })
    }
    else {
      this.routes = JSON.parse(localStorage.getItem('routes'))
    }
  }

  convertJSONtoString(con_text: any) {
    return JSON.parse(con_text).rou_name
  }

  private checkRefreshData() {
    this.crudService.singleData('ta_settings', 'set_name_data', 'refresh_data', environment.apiKey).then(res => {
      if (res) {
        if (res[0].set_datetimestamp != localStorage.getItem('refresh_data_datetimestamp')) {
          localStorage.removeItem('generalText')
          localStorage.removeItem('themes')
          localStorage.removeItem('pages')
          localStorage.removeItem('content')
          localStorage.removeItem('routes')
          localStorage.setItem('refresh_data_datetimestamp', res[0].set_datetimestamp)
        }
        this.getGeneralTextByHttpGet()
        this.getFavoritesByHttpGet()
        this.getThemesByHttpGet()
        this.getRoutesByHttpGet()
        this.getContentByHttpGet()
        this.getPagFilByHttpGet()
      }
    })
  }

  ngAfterViewInit() {
    this.currentUser.set_refresh_data = '0'
    this.crudService.editData('ta_settings', JSON.stringify(this.currentUser), environment.apiKey).then(res => {
    })
  }

  createMap(type: string, item?: any) {
    let cen ;
    let zoom ;
    let start ;
    let markerCoordinate;
    let map;
    if (type == 'overview') {
      cen = [4.13, 51.58]
      zoom = 9.5;
      start = [4.224544, 51.532241]
      markerCoordinate = [4.221395,51.532348]
      map = new mapboxgl.Map({
        accessToken: environment.mapboxAPIKey,
        container: 'map',
        style: 'mapbox://styles/dimaginecreativeagency/cl7ei0553001h14qw05qte7l3',
        zoom: zoom,
        center: cen
      })
    }
    else if(type == 'detail'){
      start = [4.220862,51.534163]
      markerCoordinate = [4.221395,51.532348]
      map = new mapboxgl.Map({
        accessToken: environment.mapboxAPIKey,
        container: 'map-detail',
        style: 'mapbox://styles/dimaginecreativeagency/cl7ei0553001h14qw05qte7l3',
        zoom: parseFloat(item.rou_zoom_map),
        center: JSON.parse(item.rou_center_long_lat)
      })
    }
    // Add Marker on Click
    return this.buildOverviewMap(map, start, markerCoordinate, type, item)
  }


  private buildOverviewMap(map, start, markerCoordinate, mapType, item?) {
    map.on('load', (event) => {
      map.resize()
      if(mapType == 'overview'){
        for(let route of this.routes){
          let markerStart = document.createElement('div')

          markerStart.className = 'marker-map-overview'
          markerStart.addEventListener('click', () =>{
            AppComponent.activateSplashscreen('experience', true)
            this.routerLink(`theme/${route.the_id}/detail/${route.rou_id}`)
          })
          new mapboxgl.Marker(markerStart).setLngLat(JSON.parse(route.rou_start_long_lat)).addTo(map)
        }
      }
      else if(mapType == 'detail'){
        // add turn instructions here at the end
        if(item){
          this.getRoute(map,item);

          let markerStart = document.createElement('div')

          markerStart.className = 'marker-map-overview marker-map-detail'
          new mapboxgl.Marker(markerStart).setLngLat(JSON.parse(item.poi[0].poi_lng_lat)).addTo(map)
          for(let mar of item.poi){
            if(mar){
              let marker = document.createElement('div');
              marker.className = 'flex-align-center detail-poi-dot'
              marker.id = 'detail-poi-dot' + mar.poi_id;

              new mapboxgl.Marker(marker).setLngLat(JSON.parse(mar.poi_lng_lat)).addTo(map)
            }

          }
        }
      }
    });
    return map;
  }
  disappearSplashScreen(){
    let elContainer = document.getElementById('container')
    if(elContainer){
      elContainer.style.height = "0"
      setTimeout(()=>{
        AppComponent.activateSplashscreen('', false)
      }, 400)
    }
  }

  async getRoute(map, item) {
    // make a directions request using cycling profile
    // an arbitrary start will always be the same
    // only the end or destination will change
    let count = 0;
    for(let coo of JSON.parse(item.rou_coordinations)){
      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/walking/${coo.coordinate}?alternatives=true&continue_straight=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=${environment.mapboxAPIKey}`,
        { method: 'GET' }
      );
      const json = await query.json();
      const data = json.routes[0];
      const route = data.geometry.coordinates;
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: route
        }
      };
      // if the route already exists on the map, we'll reset it using setData
      if (map.getSource('route')) {
        map.getSource('route').setData(geojson);
      }
      // otherwise, we'll make a new request
      else {
        map.addLayer({
          id: 'route' + count,
          type: 'line',
          source: {
            type: 'geojson',
            data: geojson
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': this.getColorCode(item.the_id),
            'line-width': 5,
            'line-opacity': 1
          }
        });
      }
      count = count + 1
    }
    if(count >= JSON.parse(item.rou_coordinations).length){
      setTimeout(()=>{
        this.disappearSplashScreen()
      },1000)
    }
  }

}
