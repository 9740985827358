import { Component, OnInit } from '@angular/core';
import {UtilsService} from "../../../services/utils.service";
import {CrudService} from "../../../services/crud.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'filter-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class FilterSearchComponent implements OnInit {
  active = false;
  placeHolder: string;

  constructor(public utilsService: UtilsService, private crudService: CrudService) { }

  ngOnInit() {
    this.crudService.singleData('ta_content', 'con_extra_info', 'search', environment.apiKey ).then(res => {
      this.placeHolder = res[0].con_text
    })

    const panelContainer = document.getElementsByTagName('app-drag-panel')[0]
    panelContainer.addEventListener('click', ()=>{
      if(this.active){
        this.active = false
      }
    })
  }

  toggle() {
    this.active = !this.active
  }
}
