import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../services/utils.service";
import {environment} from "../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {CrudService} from "../../services/crud.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string = null;
  media = environment.data.media
  theme: any;
  private the_id: string;
  windowWidth = window.innerWidth;
  constructor(public utilsService: UtilsService, private route: ActivatedRoute, private crudService: CrudService) { }

  ngOnInit() {
    this.the_id = this.route.snapshot.paramMap.get('the_id');
    if(this.the_id){
     this.getThemeByRoute()
    }
    else{
      switch(window.location.href){
        case `${window.location.origin}/#/theme/search`:
          this.getThemeByThemeTag('search')
          break;
        case `${window.location.origin}/#/theme/favorites`:
          this.getThemeByThemeTag('favorite')
          break;
        case `${window.location.origin}/#/theme/privacy`:
          this.getThemeByThemeTag('privacy')
          this.title = this.utilsService.generalText.privacyTitle
          break;
        default:
          this.the_id = '6'
          this.getThemeByRoute()
          break;
      }
    }
  }

  private getThemeByThemeTag(tag){
    this.theme = this.utilsService.themes.find(t => t.the_tag == tag)
    this.crudService.singleData('ta_content', 'con_extra_info', tag, environment.apiKey).then(res =>{
      this.theme.content = []
      this.theme.content.push(res);
    })
  }

  public setBackground() {
    if(this.theme){
      return this.theme.the_tag + '-background-header'
    }
  }

  private getThemeByRoute() {
    this.crudService.singleData("ta_theme", "the_id", Number(this.the_id), environment.apiKey).then((res: any[]) => {
      if(res){
        this.theme = res[0]
        this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['the_id', res[0].the_id], environment.apiKey).then(res2=>{
          this.theme.content = []
          this.theme.content.push(res2)
        })
      }
    })
  }
}
