import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import {UtilsService} from "../../services/utils.service";
import {AppComponent} from "../../app.component";

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  media = environment.data.media
  text;
  theme: any;
  subTitle: string;
  title: string;
  image: any;

  constructor(private route: ActivatedRoute, private utilsService: UtilsService) { }

  ngOnInit() {
  }

  getThemeColor() {
    return 'experience-bg-color'
  }
}
