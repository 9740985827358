import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, args: any): unknown {
    if (!args) {
      return value;
    }
    return value.filter((item) => {
      return item.rou_place.toLowerCase().match(args.toLowerCase()) || item.rou_name.toLowerCase().match(args.toLowerCase());
    });
  }
}
