import { Component, OnInit } from '@angular/core';
import {UtilsService} from "../../../services/utils.service";
import {CrudService} from "../../../services/crud.service";
import {environment} from "../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'filter-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss'],
})
export class FilterAccessibilityComponent implements OnInit {
  options: any = []
  private originOptions: any = [];
  active = "";
  toggleOption = false;
  theme: any;
  constructor(public utilsService: UtilsService, private crudService: CrudService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.theme = this.utilsService.themes.find(t => t.the_id == this.route.snapshot.paramMap.get('the_id')).the_tag
    this.crudService.singleDataWithTwoCondition('ta_media', ['med_id_name', 'med_id_value'], ['fil_id', '1'], environment.apiKey).then(res => {
        if(res){
          let i = 0
          for(let med of res){
            this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['acc_id', med.med_extra_info], environment.apiKey).then(res2 => {
              this.crudService.singleData('ta_accessibility', 'acc_id', med.med_extra_info, environment.apiKey).then(acc=>{
                if(acc){
                  let option ={
                    id : acc[0].acc_id,
                    index: i,
                    img: med.med_media_url,
                    acc_tag: acc[0].acc_tag,
                    acc_name: res2[0].con_text
                  }
                  i = i + 1
                  if(acc[0].acc_tag == 'walking'){
                    this.options.unshift(option)
                    this.active = acc[0].acc_tag
                  }
                  else{
                    this.options.push(option)
                  }
                  this.originOptions.push(option)
                }
            })
          })
        }
      }
    })
    const panelContainer = document.getElementsByTagName('app-drag-panel')[0]
    panelContainer.addEventListener('click', ()=>{
      if(this.active){
        this.toggleOption = false
      }
    })
  }

  toggle(){
    this.toggleOption = !this.toggleOption
    this.setStyleByOptions()
  }

  private setStyleByOptions() {
    const elOptions = document.getElementsByClassName('filter-accessibility') as HTMLCollectionOf<HTMLElement>
    const elActive = document.getElementsByClassName('filter-active') as HTMLCollectionOf<HTMLElement>
    if(this.toggleOption){
      setTimeout(()=>{
        let i = 0;
        for(let el of elOptions){
          el.style.right = `calc(53px * ${i})`
          i = i + 1;
        }
      }, 100)
    }
    else{
      setTimeout(()=>{
        elActive[0].classList.add(this.theme + '-bg-color');
        elActive[0].classList.add('filter-white-image');
        for(let el of elOptions){
          el.style.right = `0px`
        }
      }, 100)
    }
  }

  setActiveOption(option){
    this.options = []
    for(let opt of this.originOptions){
      if(opt.acc_tag == option.acc_tag){
        this.utilsService.themeAccessValue = opt.acc_name;
        this.options.unshift(opt)
        this.active = opt.acc_tag
      }
      else{
        this.options.push(opt)
      }
    }
    this.toggle()
  }
}
