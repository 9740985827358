import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilsService} from "../../services/utils.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import {CrudService} from "../../services/crud.service";
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from "@angular/material/tooltip";
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 1000,
  touchendHideDelay: 1000,
}
@Component({
  selector: 'app-summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.scss'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class SummaryItemComponent implements OnInit {
  @Input() item: any;
  headerSubTitle: string;
  data: any;
  @Input() page: string;
  @Output() typeFilter = new EventEmitter<any>();
  media = environment.data.media
  private general: any;
  theme: any;
  slider: any;
  headerImage: any;
  constructor(public utilsService: UtilsService, private route: ActivatedRoute, private crudService: CrudService) { }

  ngOnInit() {
    const the_id = this.route.snapshot.paramMap.get('the_id')
    this.slider = [];
    if(this.page == 'routes'){
      this.headerImage = this.item.slider[0]
      if(this.item.slider.length > 1){
        for(let i = 1; i < this.item.slider.length; i++){
          this.slider.push(this.item.slider[i])
        }
      }
    }
    if(the_id){
      this.theme = this.utilsService.getThemeTag(the_id)
    }
    else{
      this.theme = this.utilsService.getThemeTag('6')
    }
    this.crudService.singleDataWithTwoCondition('ta_content', ['con_id_name', 'con_id_value'], ['general', '1'], environment.apiKey).then(res =>{
      this.general = JSON.parse(res[0].con_text)
    })
    this.typeFilter.emit('map')
  }

  getInformationByItem(item: any) {
    return `
      ${this.general?.durationText} ${item.rou_duration} ${this.general?.durationUnitText}
      ${this.general?.distanceText} ${item.rou_distance}${this.general?.distanceUnitText}
    `
  }

  convertJSONtoString(con_text: any, prop: string) {
    if(con_text){
      return JSON.parse(con_text)[prop]
    }
  }

  toggleToolTip(tooltip: any){
    tooltip.toggle()
    setTimeout(()=>{
      tooltip.hide()
    },1000)
  }

  showParkingPlace(parking: any, item){
    localStorage.setItem('parking', JSON.stringify(parking));
    this.typeFilter.emit('map')
  }
}
