import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {NavigationService} from "../../services/navigation.service";
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../services/utils.service";
import {CrudService} from "../../services/crud.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'app-return-button',
  templateUrl: './return-button.component.html',
  styleUrls: ['./return-button.component.scss'],
})
export class ReturnButtonComponent implements OnInit {
  media = environment.data.media
  theme: string;
  @Input() page
  showDialog = false;
  data: any;
  image: string;
  constructor(private navigationService: NavigationService,private route: ActivatedRoute, public utilsService: UtilsService, private crudService: CrudService, private dialog: MatDialog) { }

  ngOnInit() {
  }

  routerLink(){
    if(this.page == 'route'){
      this.openDialog()
    }
    else{
      this.navigationService.goBack()
    }
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: `${window.innerWidth}px`,
      height: `${window.innerHeight}px`
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        localStorage.removeItem('route')
        this.utilsService.routerLink(`/`, true)
      }
    });
  }
}
