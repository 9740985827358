import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: 'filter-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class FilterMapComponent implements OnInit {
  filter: string;
  theme: string;
  data: any;
  private id: string;
  @Output() typeFilter = new EventEmitter<any>();
  constructor(private route: ActivatedRoute, public utilsService: UtilsService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('the_id')
    if(!this.id){
      this.id = '6';
    }
    this.theme = this.utilsService.getThemeTag(this.id)
    this.filter = this.route.snapshot.paramMap.get('filter')
  }

  routerLink() {
    this.typeFilter.emit('map')
  }
}
