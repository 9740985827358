// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  distance: 0.10, //km
  apiKey: '8r4dHaKbFWoCUxgrvEcSelX7sNYRBjesFLWl2YsoIl83N2qHjFh3XCfZaB70XH0td7iVjGt0TgAJZibdTyFjoAhrj9h3b5U3yvHnh89J9jVk9wQcWRrXSNjLQz11FzpwdtWLkCK8Yl3QHXlwv8aqqK7im3NFuqp8pBiFVWCzT5TADVHeQTWdG8lvf9x9j3SDllUDf0BYRolPv9vvNu4Y14P05i5v62d9f5Y3',
  themeColorValues: {
    experience: "#6AB023",
    inspiring: "#009EE0",
    education: "#adadad",
    informative:"#F7A50E",
    general: "#00aeef",
    route: "#00aeef"
  },
  data: {
    filters: [
      {
        id: 1,
        icon: ["assets/icon/filters/tholen_app_filter_accessibility.png"],
        componentName: "FilterAccessibilityComponent"
      },
      {
        id: 2,
        icon: ["assets/icon/filters/tholen_app_filter_attractions.png"],
        componentName: "FilterAttractionsComponent"
      },
      {
        id: 4,
        icon: ["assets/icon/filters/tholen_app_filter_current_location.png"],
        componentName: "FilterCurrentLocationComponent"
      },
      {
        id: 5,
        icon: ["assets/icon/filters/tholen_app_filter_favorite.png"],
        componentName: "FilterFavoriteComponent"
      },
      {
        id: 6,
        icon: ["assets/icon/filters/tholen_app_filter_information.png"],
        componentName: "FilterInformationComponent"
      },
      {
        id: 7,
        icon: ["assets/icon/filters/tholen_app_filter_map.png"],
        componentName: "FilterMapComponent"
      },
      {
        id: 8,
        icon: ["assets/icon/filters/tholen_app_filter_more.png"],
        componentName: "FilterMoreComponent"
      },
      {
        id: 9,
        icon: ["assets/icon/filters/tholen_app_filter_poi.png"],
        componentName: "FilterPoiComponent"
      },
      {
        id: 10,
        icon: ["assets/icon/filters/tholen_app_filter_route.png"],
        componentName: "FilterRouteComponent"
      },
      {
        id: 11,
        icon: ["assets/icon/filters/tholen_app_filter_search.png"],
        componentName: "FilterSearchComponent"
      },
      {
        id: 12,
        icon: ["assets/icon/filters/tholen_app_filter_start_route.png"],
        componentName: "FilterStartRouteComponent"
      },
      {
        id: 15,
        icon: ["assets/icon/filters/tholen_app_filter_speak.png"],
        componentName: "FilterSpeakComponent"
      }
    ],
    language:{
      nl: {
        main: {
          mainTitle1: 'Eiland',
          mainTitle2: 'Tholen',
          mainChooseDeparture: "Kies uw vertrekpunt",
          mainMenuExperience1: 'Beleving',
          mainMenuExperience2: 'Alle plekjes en routes',
          mainMenuInspiring1: 'Inspirerend',
          mainMenuInspiring2: 'Wat je echt moet zien',
          mainMenuEducation1: 'Educatief',
          mainMenuEducation2: 'Voor scholen',
          mainMenuInformative1: 'Informerend',
          mainMenuInformative2: 'Praktisch om te weten'
        },
        experience: {
          filterMsg: "Resultaten op basis van uw filters",
          filters: {
            overview: {
              filterIds: [11, 10, 1, 2]
            },
            detail: {
              filterIds: [12, 5],
              slider:['assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png']
            }
          },
          items: [
            {
              id: 1,
              title: "Parelroute Tholen-stad",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',
              url: 'theme/experience/detail/1',
              slider:['assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png'],
              information: {
                parking: {
                  title: 'Parkeren',
                  addresses:[
                    {
                      address: 'Contre Escarpe, 4691 EG Tholen',
                      icons: ['assets/tholen_app_car_icon.png', "assets/tholen_app_motor_icon.png"]
                    },
                    {
                      address: 'Contre Escarpe, 4691 EG Tholen',
                      icons: ['assets/tholen_app_car_icon.png', "assets/tholen_app_motor_icon.png"]
                    }
                  ]
                }

              },
              poi:{
                items:[
                  {
                    slideNumber: 1,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 1',
                    text: "Silde 1: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                  {
                    slideNumber: 2,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 2',
                    text: "Silde 2: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                  {
                    slideNumber: 3,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 3',
                    text: "Slide 3: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                ]
              }
            },
            {
              id: 2,
              title: "Parelroute Scherpenisse",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',

              url: 'theme/experience/detail/2'
            },
            {
              id: 3,
              title: "Parelroute Sint-Philipsland",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',

              url: 'theme/experience/detail/3'
            },
          ]
        },
        inspiring: {
          filterMsg: "Resultaten op basis van uw filters",
          filters: {
            overview: {
              filterIds: [11, 10, 1, 2]
            },
            detail: {
              filterIds: [12, 5],
              slider:['assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png']
            }
          },
          items: [
            {
              id: 1,
              title: "Parelroute Tholen-stad",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',
              url: 'theme/experience/detail/1',
              slider:['assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png'],
              information: {
                parking: {
                  title: 'Parkeren',
                  addresses:[
                    {
                      address: 'Contre Escarpe, 4691 EG Tholen',
                      icons: ['assets/tholen_app_car_icon.png', "assets/tholen_app_motor_icon.png"]
                    },
                    {
                      address: 'Contre Escarpe, 4691 EG Tholen',
                      icons: ['assets/tholen_app_car_icon.png', "assets/tholen_app_motor_icon.png"]
                    }
                  ]
                }

              },
              poi:{
                items:[
                  {
                    slideNumber: 1,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 1',
                    text: "Silde 1: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                  {
                    slideNumber: 2,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 2',
                    text: "Silde 2: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                  {
                    slideNumber: 3,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 3',
                    text: "Slide 3: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                ]
              }
            },
            {
              id: 2,
              title: "Parelroute Scherpenisse",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',

              url: 'theme/experience/detail/2'
            },
            {
              id: 3,
              title: "Parelroute Sint-Philipsland",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',

              url: 'theme/experience/detail/3'
            },
          ]
        },
        education: {
          filterMsg: "Resultaten op basis van uw filters",
          filters: {
            overview: {
              filterIds: [11, 10, 1, 2]
            },
            detail: {
              filterIds: [12, 5],
              slider:['assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png']
            }
          },
          items: [
            {
              id: 1,
              title: "Parelroute Tholen-stad",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',
              url: 'theme/experience/detail/1',
              slider:['assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png'],
              information: {
                parking: {
                  title: 'Parkeren',
                  addresses:[
                    {
                      address: 'Contre Escarpe, 4691 EG Tholen',
                      icons: ['assets/tholen_app_car_icon.png', "assets/tholen_app_motor_icon.png"]
                    },
                    {
                      address: 'Contre Escarpe, 4691 EG Tholen',
                      icons: ['assets/tholen_app_car_icon.png', "assets/tholen_app_motor_icon.png"]
                    }
                  ]
                }

              },
              poi:{
                items:[
                  {
                    slideNumber: 1,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 1',
                    text: "Silde 1: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                  {
                    slideNumber: 2,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 2',
                    text: "Silde 2: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                  {
                    slideNumber: 3,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 3',
                    text: "Slide 3: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                ]
              }
            },
            {
              id: 2,
              title: "Parelroute Scherpenisse",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',

              url: 'theme/experience/detail/2'
            },
            {
              id: 3,
              title: "Parelroute Sint-Philipsland",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',

              url: 'theme/experience/detail/3'
            },
          ]
        },
        informative: {
          filterMsg: "Resultaten op basis van uw filters",
          filters: {
            overview: {
              filterIds: [11, 10, 1, 2]
            },
            detail: {
              filterIds: [12, 5],
              slider:['assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png']
            }
          },
          items: [
            {
              id: 1,
              title: "Parelroute Tholen-stad",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',
              url: 'theme/experience/detail/1',
              slider:['assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png', 'assets/slider-1.png'],
              information: {
                parking: {
                  title: 'Parkeren',
                  addresses:[
                    {
                      address: 'Contre Escarpe, 4691 EG Tholen',
                      icons: ['assets/tholen_app_car_icon.png', "assets/tholen_app_motor_icon.png"]
                    },
                    {
                      address: 'Contre Escarpe, 4691 EG Tholen',
                      icons: ['assets/tholen_app_car_icon.png', "assets/tholen_app_motor_icon.png"]
                    }
                  ]
                }

              },
              poi:{
                items:[
                  {
                    slideNumber: 1,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 1',
                    text: "Silde 1: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                  {
                    slideNumber: 2,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 2',
                    text: "Silde 2: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                  {
                    slideNumber: 3,
                    slider: ['assets/tholen_app_poi_slide_1.png','assets/tholen_app_poi_slide_2.png'],
                    title: 'Stoomtram 3',
                    text: "Slide 3: Na de aanleg van de Thoolse-brug in 1928 kon de toenmalige stroomtram vanuit Bergen op Zoom tot in Tholen rijden. <br>" +
                      "<br>" +
                      "Op deze locatie aan de Eendrachtsweg was het eindpunt en het wachtlokaal. \n" +
                      "Hier wachte de bus op passagiers voor verder vervoer over het eiland.\n"
                  },
                ]
              }
            },
            {
              id: 2,
              title: "Parelroute Scherpenisse",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',

              url: 'theme/experience/detail/2'
            },
            {
              id: 3,
              title: "Parelroute Sint-Philipsland",
              subTitle: "Duur: +/- 2 uur   Lengte: 3,5km   Toegankelijk: Beperkt <br>  Beoordeling: 8,7   Interesses: Historie, Muziek, Kunst",
              summary: 'Tholen, een stad met het water verbonden. Kijk uit over de haven die door de mossel en oestervangst en de pleziervaart groot werd. Zoek de sporen van de tramverbinding die \n' +
                'hier liep. Verbind de overblijfselen van deze vestingstad en ontdek haar rijkdom. Vandaar dat je haar hier kunt vinden, de parel van de verbinding!\n',

              url: 'theme/experience/detail/3'
            },
          ]
        },
        general: {
          experience:{
            title: "Beleving",
            subTitle: 'Alle plekjes en routes'
          },
          inspiring:{
            title: "Inspirerend",
            subTitle: 'Wat je echt moet zien'
          },
          education:{
            title: "Educatief",
            subTitle: 'Voor scholen'
          },
          informative:{
            title: "Informerend",
            subTitle: 'Praktisch om te weten'

          },
          route:{
            title: "Route",
          },
          durationText: "Duur: +/- ",
          distanceText: "Length: ",
          distanceUnitText: "km",
          durationUnitText: 'uur',
          accessibilityText: "Toegankelijk: ",
          ratingText: "Beoordeling: ",
          interessesText: "Interesses: ",
        },
        route: {
          routeInfoTitle: "Algemene informatie",
          linkToMap: 'Toon locatie op kaart',
          linkToCopy: 'Kopieer adres',
          filters: {
            information: {
              filterIds: [6, 9, 7, 8]
            },
            poi:{
              filterIds: [15, 6, 9, 7, 8]
            },
            map:{
              filterIds: [4, 6, 9, 7, 8]
            }
          }
        }
      },
      en: {

      },
      de: {

      },
      fr: {

      }
    },
    media: {
      experience:{
        themeLogo: 'assets/animations/GemthoApp_icon_animatie_beleving.png',
        themeLogoGif: 'assets/animations/GemthoApp_icon_animatie_beleving.gif',
        headerImage: 'assets/tholen_app_header_experience.png',
      },
      inspiring:{
        themeLogo: 'assets/animations/GemthoApp_icon_animatie_inspirerend.png',
        themeLogoGif: 'assets/animations/GemthoApp_icon_animatie_inspirerend.gif',
        headerImage: 'assets/tholen_app_header_inspiring.png',
      },
      education:{
        themeLogo: 'assets/animations/GemthoApp_icon_animatie_educatief.png',
        themeLogoGif: 'assets/animations/GemthoApp_icon_animatie_educatief.gif',
        headerImage: 'assets/tholen_app_header_education.png',
      },
      informative:{
        themeLogo: 'assets/animations/GemthoApp_icon_animatie_informerend.png',
        themeLogoGif: 'assets/animations/GemthoApp_icon_animatie_informerend.gif',
        headerImage: 'assets/tholen_app_header_informative.png',
      },
      route:{
        themeLogo: 'assets/tholen_app_logo_route.png',
      },
      avatar: 'assets/tholen_app_avatar_disable.png',
      routeStartGif: 'assets/animations/GemthoApp_icon_animatie_route_wit.gif',
      routeGif: 'assets/animations/GemthoApp_icon_animatie_routefilter.gif',
      mainHeader : 'assets/movie.mp4',
      logo: 'assets/tholen_app_logo.png',
      logoBackground: 'assets/tholen_app_logo_background.png',
      experienceLogo: 'assets/tholen_app_map_logo.png',
      inspiringLogo: 'assets/tholen_app_inspired_logo.png',
      informativeLogo: 'assets/tholen_app_information_logo.png',
      educationLogo: 'assets/tholen_app_education_logo.png',
      amusementDisable: 'assets/tholen_app_amusment_disable.png',
      arrowLeft: 'assets/tholen_app_arrow_left.png',
      carIcon: 'assets/tholen_app_car_icon.png',
      chevronDown: 'assets/tholen_app_chevron_down.png',
      chevronDownUp: 'assets/tholen_app_chevron_up_down.png',
      copyIcon: 'assets/tholen_app_copy_icon.png',
      favoriteDisable: 'assets/icon/filters/tholen_app_filter_favorite.png',
      flowIcon: 'assets/tholen_app_flow_icon.png',
      logoMarker: 'assets/tholen_app_logo_marker.png',
      mapIcon: 'assets/tholen_app_map_icon.png',
      mapIconMini: 'assets/tholen_app_map_icon_mini.png',
      externLinkIcon: 'assets/icon/tholen_app_extern_link.png',
      moreIcon: 'assets/tholen_app_more_icon.png',
      motorIcon: 'assets/tholen_app_motor_icon.png',
      rectangleWhite: "assets/rectangle.png",
      routeActive: 'assets/tholen_app_route_active.png',
      routeDisable: 'assets/tholen_app_route_disable.png',
      routeIcon: 'assets/tholen_app_route_icon.png',
      searchActive: 'assets/icon/filters/tholen_app_filter_search.png',
      searchDisable: 'assets/tholen_app_search_disable.png',
      experienceSliderDetail: ['assets/tholen_app_search_disable.png'],
      mapBoxDummy: 'assets/tholen_app_dummy_map.png',
      mapBoxDummyRoute: 'assets/tholen_app_dummy_map_route.png',
      mapBoxDummyStartRoute: 'assets/tholen_app_dummy_map_start_route.png',
    },
    favorites: []
  },
  mapboxAPIKey: 'pk.eyJ1IjoiZGltYWdpbmVjcmVhdGl2ZWFnZW5jeSIsImEiOiJja3RiNGp6NWkwMXl2MnFyd3ZxMG9nMDgzIn0.xw0eLfDjyDEp7tygXrTm0w',
  apiUrlNode: 'https://nodejs.imaginecreativeagency.nl/tholen-app'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
