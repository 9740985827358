import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {UtilsService} from "../../../services/utils.service";
import {CrudService} from "../../../services/crud.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../../dialog/dialog.component";

@Component({
  selector: 'app-stop-route',
  templateUrl: './stop-route.component.html',
  styleUrls: ['./stop-route.component.scss'],
})
export class FilterStopRouteComponent implements OnInit {
  data: any;
  media = environment.data.media
  theme: string;
  image: string;
  constructor(private route: ActivatedRoute, public utilsService: UtilsService, private crudService: CrudService, private dialog: MatDialog) { }

  ngOnInit() {
    this.image = 'assets/icon/filters/tholen_app_filter_stop.svg'
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.routerLink()
      }
    });
  }

  routerLink(){
    localStorage.removeItem('route')
    this.utilsService.routerLink(`/`, true)
  }
}
