import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import * as mapboxgl from "mapbox-gl"
import {UtilsService} from "../../../services/utils.service";
import {Geolocation} from "@awesome-cordova-plugins/geolocation/ngx";


@Component({
  selector: 'app-map-box-detail',
  templateUrl: './map-box-detail.component.html',
  styleUrls: ['./map-box-detail.component.scss'],
})
export class MapBoxDetailComponent implements OnInit {
  media = environment.data.media
  style = 'mapbox://styles/dimaginecreativeagency/cl7ei0553001h14qw05qte7l3';
  mapDetail!: mapboxgl.Map;
  private currentLat: number;
  private currentLng: number;
  @Input() item: any;
  private error: any;

  constructor(private geolocation: Geolocation, public utilsService: UtilsService) {}

  ngOnInit(): void {
    if(this.item) {
      this.utilsService.createMap('detail', this.item)
    }
  }
}
