import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UtilsService} from "../../services/utils.service";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  stop = false;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    public utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
  ) {}

  onClick(bool): void {
    this.dialogRef.close(bool);
  }
}


